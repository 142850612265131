var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('h1',{staticClass:"subheading grey--text"},[_vm._v("Dashboard")]),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"close":_vm.resetDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h2',[_vm._v("Add a New Project")])]),_c('v-card-text',[_c('v-form',{staticClass:"px-3"},[_c('v-text-field',{attrs:{"label":"Title","prepend-icon":"folder"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-text-field',{attrs:{"label":"Person","prepend-icon":"person"},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}}),_c('v-textarea',{attrs:{"label":"Information","prepend-icon":"edit"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('v-menu',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.due,"label":"Due date","prepend-icon":"mdi-calendar-range"}},on))]}}])},[_c('v-date-picker',{model:{value:(_vm.due),callback:function ($$v) {_vm.due=$$v},expression:"due"}})],1),_c('v-btn',{staticClass:"primary mx-0 mt-3",attrs:{"text":""},on:{"click":_vm.postProject}},[_vm._v("Add project")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"close":_vm.resetDialog},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[_c('v-card',[_c('v-card-title',[_c('h2',[_vm._v("Update Project")])]),_c('v-card-text',[_c('v-form',{staticClass:"px-3"},[_c('v-text-field',{attrs:{"label":"Title","prepend-icon":"folder"},model:{value:(_vm.updateTitle),callback:function ($$v) {_vm.updateTitle=$$v},expression:"updateTitle"}}),_c('v-text-field',{attrs:{"label":"Person","prepend-icon":"person"},model:{value:(_vm.updatePerson),callback:function ($$v) {_vm.updatePerson=$$v},expression:"updatePerson"}}),_c('v-textarea',{attrs:{"label":"Information","prepend-icon":"edit"},model:{value:(_vm.updateContent),callback:function ($$v) {_vm.updateContent=$$v},expression:"updateContent"}}),_c('v-menu',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.updateDue,"label":"Due date","prepend-icon":"mdi-calendar-range"}},on))]}}])},[_c('v-date-picker',{model:{value:(_vm.updateDue),callback:function ($$v) {_vm.updateDue=$$v},expression:"updateDue"}})],1),_c('v-btn',{staticClass:"primary mx-0 mt-3",attrs:{"text":""},on:{"click":_vm.postUpdateProject}},[_vm._v("Update project")])],1)],1)],1)],1),_c('v-btn',{staticClass:"success",on:{"click":_vm.openDialog}},[_vm._v("Add new project")]),_c('v-container',{staticClass:"my-5"},[_c('v-row',{staticClass:"mb-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.sortByProject('title')}}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-folder")]),_c('span',{staticClass:"caption text-lowercase",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.sortProp === "title" && _vm.sortOrder === "asc" ? "by projects name (A - Z)" : "by projects name (Z - A)")+" ")])],1)]}}])},[_c('span',[_vm._v("Sorts projects by name")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.sortByProject('person')}}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-account")]),_c('span',{staticClass:"caption text-lowercase",attrs:{"right":""}},[_vm._v(_vm._s(_vm.sortProp === "person" && _vm.sortOrder === "asc" ? "by person (A - Z)" : "by person (Z - A)"))])],1)]}}])},[_c('span',[_vm._v("Sorts projects By person")])])],1),_c('div',_vm._l((_vm.sortedProjects),function(item){return _c('v-card',{key:item._id,staticClass:"px-3",class:_vm.projectStatus(item.due),attrs:{"text":"","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.openUpdateDialog(item._id)}}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"pl-3",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Project Title")]),_c('div',[_vm._v(_vm._s(item.title))])]),_c('v-col',{attrs:{"xs":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Person")]),_c('div',[_vm._v(_vm._s(item.person))])]),_c('v-col',{attrs:{"xs":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Due Date")]),_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.due)))])]),_c('v-col',{attrs:{"xs":"2"}},[_c('div',[_c('v-chip',{class:((_vm.projectStatus(
                  item.due
                )) + "-chip v-chip--active white--text caption my-2"),attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.projectStatus(item.due))+" ")])],1)]),_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProject(item._id)}}},[_vm._v("DELETE")])],1),_c('v-row',{attrs:{"cols":"12"}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }